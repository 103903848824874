import { FC } from 'react';
import tw, { styled } from 'twin.macro';

type ButtonArrowDoubleProps = {
  disabled?: boolean;
  isPrev?: boolean;
  onClick: () => void;
  ariaLabel?: string;
};

/**
 *
 */
export const ButtonRoundedArrow: FC<ButtonArrowDoubleProps> = ({
  disabled = false,
  isPrev = false,
  onClick,
  ariaLabel,
}) => {
  return (
    <Button
      disabled={disabled}
      onClick={() => !disabled && onClick()}
      aria-label={ariaLabel}
    >
      <span tw="sr-only">{`Go to ${isPrev ? 'previous' : 'next'}`}</span>
      <Arrow isPrev={isPrev} isInner={!isPrev} />
    </Button>
  );
};

const Arrow = ({ isPrev, isInner = false }) => (
  <svg
    tw="fill-theme-fg w-3.5 h-3.5"
    css={[
      isInner && tw`mx-[-0.1875rem]`,
      !isPrev && tw`[transform: rotate(180deg)]`,
    ]}
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.125 8.74289C-0.0416647 8.06932 -0.0416664 6.38538 1.125 5.7118L8.77291 1.29628C10.0435 0.562731 11.5984 1.62424 11.3781 3.07469L10.7985 6.88938C10.7644 7.11341 10.7644 7.34129 10.7985 7.56532L11.3781 11.38C11.5984 12.8305 10.0435 13.892 8.77292 13.1584L1.125 8.74289Z"
      stroke="#45413C"
      strokeWidth="0.5"
    />
  </svg>
);

const Button = styled.button(({ disabled }) => [
  tw`relative flex items-center justify-center w-14 h-14 my-2`,
  disabled && tw`opacity-40`,
  `
    &:hover {
      .fg {
        opacity: 1;
        fill: var(--theme-base);
      }
    }
    &:active {
      .fg {
        fill: var(--theme-base-invert);
      }
    }
  }`,
]);
