import { theme } from 'twin.macro';
import { useIsMobile } from '~/hooks';

export const JauntyLinkedInIcon = ({ width = '20', height = '20' }) => {
  const isMobile = useIsMobile();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1160_628)">
        <path
          d="M0.583374 19.6594V6.82575H4.26525V19.6594H0.583374Z"
          fill={
            isMobile ? theme`colors.theme-fg` : theme`colors.theme-base-invert`
          }
          stroke="#45413C"
          strokeWidth="0.5"
        />
        <path
          d="M15.8045 6.52907L15.7909 6.52758C15.6383 6.51084 15.4822 6.49876 15.3234 6.49226L15.8045 6.52907ZM15.8045 6.52907H15.8055M15.8045 6.52907H15.8055M15.8055 6.52907C17.0832 6.66957 18.0596 7.1576 18.7196 7.94383C19.3836 8.73492 19.751 9.85622 19.751 11.3029V19.75H16.0691V12.2268C16.0691 12.1127 16.0547 11.4899 15.7218 10.8875C15.5533 10.5825 15.3014 10.2798 14.9277 10.0543C14.5536 9.82866 14.0713 9.68882 13.4551 9.68882C12.5936 9.68882 11.8097 10.0747 11.376 10.8603L11.3759 10.8605C10.9424 11.6472 10.9519 12.5568 10.9602 13.358C10.9613 13.4657 10.9624 13.5715 10.9624 13.6747V19.6893H7.28052V6.85565H10.7803V8.38976V9.29972L11.245 8.51785L11.2451 8.51774L11.2451 8.51764L11.2452 8.51762L11.2452 8.51757L11.2452 8.51753L11.2452 8.51749L11.2465 8.51545L11.2533 8.50446C11.2597 8.49427 11.2698 8.47847 11.2837 8.45776C11.3113 8.41642 11.3537 8.35561 11.4108 8.28086C11.8446 7.71814 13.0994 6.40167 15.3234 6.49226L15.8055 6.52907Z"
          fill={
            isMobile ? theme`colors.theme-fg` : theme`colors.theme-base-invert`
          }
          stroke="#45413C"
          strokeWidth="0.5"
        />
        <path
          d="M4.59948 2.42474C4.59948 3.62581 3.62581 4.59948 2.42474 4.59948C1.22366 4.59948 0.25 3.62581 0.25 2.42474C0.25 1.22366 1.22366 0.25 2.42474 0.25C3.62581 0.25 4.59948 1.22366 4.59948 2.42474Z"
          fill={
            isMobile ? theme`colors.theme-fg` : theme`colors.theme-base-invert`
          }
          stroke="#45413C"
          strokeWidth="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_1160_628">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
