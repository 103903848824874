import { AnimatePresence, motion } from 'framer-motion';
import { FC, ReactNode, useContext, useMemo } from 'react';
import Modal from 'react-modal';
import tw, { styled } from 'twin.macro';
import { ButtonClose } from '~/components/ButtonClose';
import { DayContext } from '~/context';
import { anim, easeOutQuart, variant } from '~/utils';
import { customTagWithDate } from '~/utils/tagging';

type ShareModalProps = {
  socialLinks: {
    name: string;
    link: string;
    icon: ReactNode;
  }[];
  close: () => void;
  isCopied: boolean;
  isOpen: boolean;
  handleCopy: () => Promise<void>;
};

/**
 * ShareModal for mobile
 */
export const ShareModal: FC<ShareModalProps> = ({
  socialLinks,
  close,
  isOpen,
  handleCopy,
  isCopied,
}) => {
  const { day } = useContext(DayContext);
  const modalVariants = useMemo(() => {
    return {
      ready: {
        leave: anim({ opacity: 0, y: 0 }, 0), // instant
        enter: anim(
          { opacity: [0, 1, 1, 1], y: [0, 0, -4, 0] },
          0.55,
          1.9,
          easeOutQuart,
          [0, 0, 0.5, 1],
        ),
        initial: { opacity: 0, y: 0 },
      },
      dialog: {
        leave: anim({ opacity: [1, 0], y: [0, -8] }, 0.2, 0, easeOutQuart),
        enter: anim(
          { opacity: [0, 1, 1, 1], y: [0, 0, -8, 0] },
          1,
          0.1,
          easeOutQuart,
          [0, 0, 0.33, 1],
        ),
        initial: { opacity: 0, y: 0 },
      },
    };
  }, []);

  return (
    <AnimatePresence>
      <Modal
        isOpen={isOpen}
        onRequestClose={close}
        className="ReactModal__Content"
        contentLabel="Example Modal"
      >
        <ModalTW {...variant(modalVariants.dialog)}>
          <BoxShadow />
          <ModalContent>
            <div tw="absolute right-0 top-0 -mt-4 -mr-4">
              <ButtonClose onClick={close} />
            </div>
            <SocialLinkList>
              {socialLinks.map((link, idx) => (
                <li key={link.name}>
                  {link.name === 'CopyToClipboard' ? (
                    <button onClick={handleCopy} css={modalLinkStyles}>
                      <span>copy link</span>
                      <span>{link.icon}</span>
                      {isCopied && (
                        <div tw="absolute -top-4 text-lg text-theme-fg">
                          Copied!
                        </div>
                      )}
                    </button>
                  ) : (
                    <a
                      href={link.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      css={modalLinkStyles}
                      onClick={() =>
                        customTagWithDate(
                          link.name.toLowerCase(),
                          day.data.theDate!,
                          day.data.initiative!,
                        )
                      }
                    >
                      <span>{link.name}</span>
                      <span>{link.icon}</span>
                    </a>
                  )}
                  {/* Jaunty underline for all but last item */}
                  {idx < socialLinks.length - 1 && (
                    <svg
                      width="189"
                      height="3"
                      viewBox="0 0 189 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 2L38.7126 1L74.1737 2H87.6826L107.383 1.66667L189 2"
                        stroke="#45413C"
                        strokeWidth="0.5"
                      />
                    </svg>
                  )}
                </li>
              ))}
            </SocialLinkList>
          </ModalContent>
        </ModalTW>
      </Modal>
    </AnimatePresence>
  );
};

const BoxShadow = tw.div`absolute inset-0 -bottom-2 -right-0.5 bg-theme-fg opacity-30 rounded-[1.6875rem] [max-width: 90vw] h-auto w-full`;
const ModalContent = tw.div`relative sm:absolute inset-0 flex items-center justify-center bg-white rounded-[1.6875rem]`;
const SocialLinkList = tw.ul`flex flex-col justify-center items-center w-full py-4 px-12`;
const modalLinkStyles = tw`flex justify-center items-center w-full gap-2 py-4 text-2xl lowercase text-theme-fg text-center relative`;
const ModalTW = styled(motion.aside)(() => [tw`w-full h-auto relative`]);
