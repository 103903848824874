import { theme } from 'twin.macro';
import { useIsMobile } from '~/hooks';

export const JauntyTwitterIcon = ({ width = '23', height = '20' }) => {
  const isMobile = useIsMobile();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 17.1356C3.45323 17.2772 5.59317 16.7016 7.68957 15.2023C5.57724 14.8458 4.10849 13.8959 3.30986 11.9164C3.92264 11.8283 4.46957 11.9887 5.10677 11.7424C3.02737 10.8417 1.67968 9.48818 1.62446 7.15012C2.27972 7.20674 2.77886 7.62822 3.5658 7.57265C1.64889 5.72422 1.06797 3.71643 2.27228 1.26934C4.26991 3.53819 6.60951 5.06999 9.45781 5.79447C9.61286 5.83431 9.76791 5.87625 9.92296 5.91085C10.6611 6.0786 11.5277 6.43717 11.9748 6.38265C12.7436 6.28724 11.9748 5.41703 12.1702 4.28469C12.7798 0.760835 16.706 -0.83806 19.6138 1.2253C20.4634 1.82816 21.1377 1.81768 21.9672 1.45386C22.4058 1.262 22.8486 1.07852 23.3669 0.857293C23.2469 1.868 22.4674 2.4153 21.8418 3.16809C22.5555 3.3285 23.1301 3.1251 23.7811 2.92065C23.5549 3.64618 23.0111 4.05613 22.5098 4.45454C21.9937 4.86449 21.7877 5.29226 21.7664 5.96432C21.4457 16.1218 9.75623 23.9799 1.76146 17.7259C0.986194 17.1199 1.74128 17.7269 1 17.1335V17.1356Z"
        fill={
          isMobile ? theme`colors.theme-fg` : theme`colors.theme-base-invert`
        }
        stroke="#45413C"
        strokeWidth="0.5"
      />
    </svg>
  );
};
