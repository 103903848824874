import { theme } from 'twin.macro';
import { useIsMobile } from '~/hooks';

export const JauntyFacebookIcon = ({ width = '23', height = '23' }) => {
  const isMobile = useIsMobile();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.65 14.9875V15.2375H6.9H8.95V22.5165C3.97001 21.3548 0.25 16.8808 0.25 11.5288C0.25 5.30239 5.29066 0.25 11.5 0.25C17.7093 0.25 22.75 5.30239 22.75 11.5288C22.75 17.2882 18.4484 22.0317 12.9 22.7205V15.2375H16.1H16.35V14.9875V11.5288V11.2788H16.1H12.9V9.22306C12.9 8.72645 13.3062 8.32018 13.8 8.32018H16.1H16.35V8.07018V4.61153V4.36153H16.1H13.225C10.8668 4.36153 8.95 6.28407 8.95 8.64662V11.2788H6.9H6.65V11.5288V14.9875Z"
        fill={
          isMobile ? theme`colors.theme-fg` : theme`colors.theme-base-invert`
        }
        stroke="#45413C"
        strokeWidth="0.5"
      />
    </svg>
  );
};
