export const ShareIcon = ({
  color = 'var(--theme-base-invert)',
  stroke = '',
  width = '21',
  height = '21',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 21"
    fill={color}
    stroke={stroke}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.50004 13C5.37469 12.9974 6.21626 12.6653 6.85704 12.07L13.117 15.647C12.9073 16.4666 12.9998 17.3343 13.3775 18.0913C13.7552 18.8483 14.393 19.4439 15.174 19.7692C15.955 20.0944 16.827 20.1274 17.6304 19.8623C18.4338 19.5971 19.1148 19.0515 19.5488 18.3252C19.9827 17.599 20.1406 16.7408 19.9935 15.9076C19.8464 15.0745 19.4042 14.3222 18.7478 13.7885C18.0914 13.2548 17.2647 12.9753 16.419 13.0013C15.5734 13.0273 14.7655 13.357 14.143 13.93L7.88304 10.353C7.94904 10.103 7.98504 9.84396 7.99104 9.58496L14.141 6.06996C14.7332 6.60874 15.4927 6.92747 16.292 6.97268C17.0913 7.01789 17.8818 6.78684 18.531 6.31828C19.1802 5.84972 19.6484 5.17217 19.8572 4.39929C20.0661 3.6264 20.0027 2.80522 19.6779 2.0735C19.353 1.34178 18.7864 0.744037 18.0731 0.38056C17.3597 0.0170831 16.5431 -0.0900175 15.7602 0.077226C14.9772 0.244469 14.2756 0.675882 13.7731 1.29909C13.2705 1.92229 12.9976 2.69937 13 3.49996C13.004 3.78796 13.043 4.07496 13.117 4.35296L7.43304 7.59997C7.10341 7.09003 6.6468 6.67461 6.10805 6.39454C5.56931 6.11446 4.96697 5.97937 4.36021 6.00251C3.75346 6.02566 3.16316 6.20627 2.64732 6.52658C2.13148 6.84689 1.70785 7.29589 1.41804 7.82946C1.12823 8.36303 0.98222 8.96282 0.994351 9.56989C1.00648 10.177 1.17634 10.7704 1.48723 11.292C1.79812 11.8136 2.23936 12.2453 2.76758 12.5447C3.2958 12.8442 3.89284 13.0011 4.50004 13V13Z"
      fill={color}
      className="fg-path"
    />
  </svg>
);
