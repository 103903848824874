import {
  JauntyCopyIcon,
  JauntyFacebookIcon,
  JauntyLinkedInIcon,
  JauntyTwitterIcon,
} from '~/components/atoms/icons';

type ShareLinksProps = {
  shareUrl: string;
  shareText?: string;
};

export const shareLinks = ({ shareUrl, shareText = '' }: ShareLinksProps) => {
  // Twitter Note: Not passing the URL as a param to avoid it being tacked on at the end of the tweet
  const twitterShareUrl = `https://twitter.com/intent/tweet?${new URLSearchParams(
    { text: `${shareText}` },
  ).toString()}`;
  const linkedInShareUrl = `https://www.linkedin.com/shareArticle?mini=true&${new URLSearchParams(
    { url: shareUrl },
  ).toString()}`;
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?${new URLSearchParams(
    { u: shareUrl },
  ).toString()}`;

  const socials = [
    {
      name: 'CopyToClipboard',
      link: shareUrl,
      icon: <JauntyCopyIcon />,
    },
    {
      name: 'Twitter',
      link: twitterShareUrl,
      icon: <JauntyTwitterIcon />,
    },
    {
      name: 'LinkedIn',
      link: linkedInShareUrl,
      icon: <JauntyLinkedInIcon />,
    },
    {
      name: 'Facebook',
      link: facebookShareUrl,
      icon: <JauntyFacebookIcon />,
    },
  ];
  return socials;
};
