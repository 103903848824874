import { PortableText } from '@portabletext/react';
import { AnimatePresence, motion } from 'framer-motion';
import { PageProps as GatsbyPageProps } from 'gatsby';
import { FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import tw, { screen } from 'twin.macro';
import { JauntyButton } from '~/components/atoms/JauntyButton';
import { Dailydiff, isSSG, staticGlobals } from '~/config';
import { DayContext } from '~/context/DayContext';
import { Theme, ThemeContext } from '~/context/ThemeContext';
import { useIsMobile } from '~/hooks';
import { anim, easeOutQuint, variant } from '~/utils';
import { customTagWithDate } from '~/utils/tagging';
import { Emoji } from './atoms/Emoji';
import { DateTicker } from './DateTicker';
import { ShareMenu } from './molecules/ShareMenu';
import { ReactionTray } from './organisms/Reactions';
import { SEO } from './SEO';

// The entire "DailyDiff" object from Sanity is passed in as the context (see gatsby-node.ts)
type DayTemplateProps = GatsbyPageProps<
  object,
  Dailydiff & {
    baseUrl: string;
    ogLandcapePermalink: string;
    background?: string;
    foreground?: string;
  }
>;

const DayTemplate: FC<DayTemplateProps> = ({ pageContext }) => {
  // !isSSG && console.log(pageContext);
  const { setTheme } = useContext(ThemeContext);
  const { ready, day } = useContext(DayContext);
  const themeTimer = useRef<NodeJS.Timeout>();

  const { defaults } = staticGlobals;
  const {
    baseUrl,
    theDate,
    initiative,
    ogLandcapePermalink,
    background,
    foreground,
    url: buttonUrl,
    buttonLabel,
  } = pageContext;
  const title = `${initiative} | ${defaults.title}`;
  const description = `${pageContext['_rawDescription'][0].children[0].text}`;
  const dayUrl = `${baseUrl}/day/${theDate}/`;

  useEffect(() => {
    const dayTheme: Partial<Theme> = {
      background,
      foreground,
    };

    // Hack: if this is the first load, use a timer to kick the setTheme down the road
    // so that it is not overwritten by a randomly generated theme during boot
    if (themeTimer.current) {
      setTheme(dayTheme);
    } else {
      themeTimer.current = setTimeout(() => {
        setTheme(dayTheme);
      }, 500);
    }
    // Intentionally excluding deps from this so as to only fire on date change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theDate]);

  const handleMainLinkClick = useCallback(() => {
    customTagWithDate('charity_link', day.data.theDate!, day.data.initiative!);
    if (!isSSG) {
      window.open(buttonUrl!, '_blank', 'noopener,noreferrer');
    }
  }, [day, pageContext]);

  // If we've "stopped" on this date show it's content,
  // and immediately hide it if we start navigating away
  const hasLanded = day.data?.theDate === theDate;

  const blurbDelay = 0.43;

  const variants = useMemo(() => {
    const show = {
      opacity: 1,
      y: 0,
    };

    const hideBackward = {
      opacity: 0,
      y: 15,
    };

    const hideForward = {
      opacity: 0,
      y: -15,
    };

    const hide = day.direction > 0 ? hideForward : hideBackward;
    const initial = day.direction > 0 ? hideBackward : hideForward;

    return {
      name: {
        leave: anim(hide, 0.15),
        enter: anim(show, 0.8, blurbDelay, easeOutQuint),
        initial,
      },
      blurb: {
        leave: anim(hide, 0.15),
        enter: anim(show, 0.85, blurbDelay + 0.05, easeOutQuint),
        initial,
      },
      cta: {
        leave: anim(hide, 0.15),
        enter: anim(show, 0.9, blurbDelay + 0.15, easeOutQuint),
        initial,
      },
    };
  }, [day.direction]);

  const isMobile = useIsMobile();

  return (
    <AnimatePresence>
      <SEO
        key="seo"
        title={title}
        description={description}
        url={dayUrl}
        landscapeOgImage={ogLandcapePermalink}
      />
      {hasLanded && (
        <Section key="main-section">
          <DateTicker />
          <div tw="relative w-full text-center sm:(max-w-xl)">
            <div tw="relative pt-4 px-6">
              {/* TITLE & ICON */}
              <motion.h1
                tw="m-0 font-primary text-theme-fg sm:text-theme-base text-[1.625rem] leading-[1.25] sm:text-[2rem]"
                {...variant(variants.name, ready)}
              >
                {initiative || '[no title]'}
                {day.customReaction && (
                  <Emoji
                    emoji={day.customReaction}
                    customCss={tw`inline-block ml-2 mb-1`}
                  />
                )}
              </motion.h1>

              {/* BODY COPY */}
              <motion.div
                css={[
                  tw`font-secondary text-[0.9375rem] leading-[1.73]`,
                  screen`sm`(tw`text-[1.125rem] leading-[1.66]`),
                  `
                > p {
                  margin-top: 0.825rem;
                  font-size: inherit;
                  line-height: inherit;
                  color: var(--theme-base);
                }
              `,
                ]}
                {...variant(variants.blurb, ready)}
              >
                <PortableText value={pageContext['_rawDescription']} />
              </motion.div>

              {/* CTA BUTTON */}
              <motion.div {...variant(variants.cta, ready)}>
                {buttonUrl && buttonLabel && (
                  <div tw="flex gap-2 justify-center items-center sm:block">
                    <JauntyButton
                      customCss={[
                        tw`max-w-[20rem] mt-3 sm:(text-lg) leading-[1.1]`,
                      ]}
                      onClick={handleMainLinkClick}
                    >
                      <span tw="block leading-none py-2">{buttonLabel}</span>
                    </JauntyButton>
                    {isMobile && (
                      <ShareMenu
                        dayDate={theDate}
                        dayName={initiative}
                        dayUrl={dayUrl} // testing note: deploy preview urls are used in shares from dp's
                      />
                    )}
                  </div>
                )}
                <ReactionTray />
                {!isMobile && (
                  <ShareMenu
                    dayDate={theDate}
                    dayName={initiative}
                    dayUrl={dayUrl} // testing note: deploy preview urls are used in shares from dp's
                  />
                )}
              </motion.div>
            </div>
          </div>
        </Section>
      )}
    </AnimatePresence>
  );
};

const Section = tw.section`flex flex-col items-center justify-start w-full sm:(w-auto)`;

export default DayTemplate;
