import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { FC, useContext, useMemo } from 'react';
import tw from 'twin.macro';
import { DayContext } from '~/context/DayContext';
import { easeOutQuart, easeOutQuint } from '~/utils/easing';
import { anim, variant } from '~/utils/motion';
import { tagDateArrowClick } from '~/utils/tagging';
import { ButtonRoundedArrow } from './ButtonRoundedArrow';

type DateTickerProps = {
  //
};

/**
 * The main date display, two digit month on top, day on bottom
 */
export const DateTicker: FC<DateTickerProps> = (props) => {
  const { ready, day, requestByPath } = useContext(DayContext);

  const variants = useMemo(() => {
    const show = {
      opacity: 1,
      y: 0,
    };

    const hideBackward = {
      opacity: 0,
      y: -5,
    };

    const hideForward = {
      opacity: 0,
      y: 5,
    };

    const hide = day.direction > 0 ? hideForward : hideBackward;
    const initial = day.direction > 0 ? hideBackward : hideForward;

    return {
      ready: {
        leave: anim({ opacity: 0, y: 0 }, 0), // instant
        enter: anim(
          { opacity: [0, 1, 1, 1], y: [0, 0, -15, 0] },
          1.2,
          0,
          easeOutQuart,
          [0, 0, 0.3, 1],
        ),
        initial: { opacity: 0, y: 0 },
      },
      date: {
        dayOfWeek: {
          leave: anim(hide, 0.1),
          enter: anim(show, 0.8, 0.5, easeOutQuint),
          initial,
        },
        monthDigits: {
          leave: anim(hide, 0.1, 0),
          enter: anim(show, 0.2, 0, easeOutQuint),
          initial,
        },
      },
    };
  }, [day.direction]);

  const info = useMemo(() => {
    const theDate = dayjs(day.data?.theDate);
    return (
      theDate.isValid() && {
        theDate,
        theMonth: theDate.format('MM'),
        theDay: theDate.format('DD'),
      }
    );
  }, [day.data?.theDate]);

  return (
    <div tw="w-full sm:(pt-0 max-w-md) text-center">
      {!!day.data && !!info && (
        <motion.h2
          tw="relative text-[9rem] leading-[0.8] text-theme-fg"
          {...variant(variants.ready, ready)}
        >
          <motion.span
            tw="hidden sm:block absolute left-0 bottom-full w-full text-[1.75rem] uppercase"
            key={day.data.theDate}
            {...variant(variants.date.dayOfWeek, ready)}
          >
            {info.theDate.format('dddd')}
          </motion.span>
          <span tw="relative block">
            <NavArrow
              isPrev
              disabled={!day.prevMonth}
              onClick={() => {
                tagDateArrowClick('back', 'month');
                day.prevMonth && requestByPath(day.prevMonth);
              }}
              ariaLabel={'Go to previous month'}
            />
            <NavArrow
              disabled={!day.nextMonth}
              onClick={() => {
                tagDateArrowClick('forward', 'month');
                return day.nextMonth && requestByPath(day.nextMonth);
              }}
              ariaLabel={'Go to next month'}
            />
            <motion.span
              tw="flex justify-center"
              key={info.theMonth}
              {...variant(variants.date.monthDigits)}
            >
              <span tw="block w-[4.75rem]">{info.theMonth[0]}</span>
              <span tw="block w-[4.75rem]">{info.theMonth[1]}</span>
            </motion.span>
          </span>
          <span tw="relative block">
            <NavArrow
              isPrev
              disabled={!day.prevDay}
              onClick={() => {
                tagDateArrowClick('back', 'day');
                return day.prevDay && requestByPath(day.prevDay);
              }}
              ariaLabel={'Go to previous day'}
            />
            <NavArrow
              disabled={!day.nextDay}
              onClick={() => {
                tagDateArrowClick('forward', 'day');
                return day.nextDay && requestByPath(day.nextDay);
              }}
              ariaLabel={'Go to next day'}
            />
            <span tw="flex justify-center">
              <span tw="block w-[4.75rem]">{info.theDay[0]}</span>
              <span tw="block w-[4.75rem]">{info.theDay[1]}</span>
            </span>
          </span>
        </motion.h2>
      )}
    </div>
  );
};

const NavArrow: FC<{
  disabled?: boolean;
  isPrev?: boolean;
  onClick: () => void;
  ariaLabel?: string;
}> = ({ disabled = false, isPrev = false, onClick, ariaLabel = '' }) => (
  <div
    tw="absolute top-0 flex items-center h-full px-10 pt-6 [filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.2)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.2))]"
    css={[isPrev ? tw`left-0` : tw`right-0`]}
  >
    <ButtonRoundedArrow
      disabled={disabled}
      isPrev={isPrev}
      onClick={onClick}
      ariaLabel={ariaLabel}
    />
  </div>
);
