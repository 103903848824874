import { theme } from 'twin.macro';
import { useIsMobile } from '~/hooks';

export const JauntyCopyIcon = ({ width = '24', height = '22' }) => {
  const isMobile = useIsMobile();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5876 16.6216L14.7792 16.461L14.6186 16.2694L13.0481 14.396L12.8875 14.2044L12.6959 14.365L8.94898 17.5059C8.2186 18.1182 7.39154 18.38 6.45386 18.2975C5.51618 18.215 4.74753 17.8128 4.13527 17.0825C3.52302 16.3521 3.26124 15.525 3.34373 14.5873C3.42623 13.6497 3.82839 12.881 4.55877 12.2687L8.30569 9.1278L8.49728 8.9672L8.33667 8.77561L6.7662 6.90215L6.6056 6.71056L6.41401 6.87116L2.66709 10.0121C1.32067 11.1408 0.566946 12.584 0.413456 14.3287C0.25987 16.0744 0.749937 17.6277 1.87864 18.9741C3.0073 20.3206 4.45084 21.074 6.19605 21.227C7.94123 21.3811 9.4942 20.8913 10.8407 19.7626L14.5876 16.6216ZM8.28425 15.1994L8.44486 15.391L8.63645 15.2304L16.1303 8.94854L16.3219 8.78793L16.1613 8.59634L14.5908 6.72289L14.4302 6.5313L14.2386 6.6919L6.74476 12.9738L6.55317 13.1344L6.71378 13.326L8.28425 15.1994ZM16.1088 15.0202L16.2695 15.2118L16.461 15.0512L20.208 11.9102C21.5544 10.7815 22.3082 9.33769 22.4617 7.59194C22.6152 5.84728 22.1251 4.29461 20.9964 2.9482C19.8677 1.60178 18.4243 0.847766 16.6792 0.693634L16.679 0.693619C14.9338 0.540681 13.3808 1.03108 12.0344 2.15975L8.28747 5.30069L8.09588 5.4613L8.25648 5.65288L9.82695 7.52634L9.98756 7.71793L10.1791 7.55733L13.9261 4.41638C14.6564 3.80413 15.4835 3.54235 16.4212 3.62484C17.3589 3.70734 18.1275 4.1095 18.7398 4.83988C19.352 5.57025 19.6138 6.39731 19.5313 7.335C19.4488 8.27268 19.0467 9.04133 18.3163 9.65358L14.5694 12.7945L14.3778 12.9551L14.5384 13.1467L16.1088 15.0202Z"
        fill={
          isMobile ? theme`colors.theme-fg` : theme`colors.theme-base-invert`
        }
        stroke="#45413C"
        strokeWidth="0.5"
      />
    </svg>
  );
};
