import { motion, Variants } from 'framer-motion';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import tw, { styled } from 'twin.macro';
import { ShareIcon } from '~/components/atoms/icons';
import { JauntyButton } from '~/components/atoms/JauntyButton';
import { GooEffectSvg } from '~/components/common';
import { CustomCssProps, isSSG } from '~/config';
import { DayContext } from '~/context/DayContext';
import { useIsMobile } from '~/hooks';
import { convertDate } from '~/utils/date';
import { customTagWithDate } from '~/utils/tagging';
import { ShareModal } from './ShareModal';
import { shareLinks } from './socialLinks';

const effectId = 'share-menu-effect';

type ShareMenuProps = CustomCssProps & {
  dayDate?: string;
  dayName?: string;
  dayUrl?: string;
};

/**
 * ShareMenu for sharing metadata to social media links.
 * Passes currently-viewed Day's info into query string links for various socials
 */
export const ShareMenu: FC<ShareMenuProps> = ({
  dayName,
  dayDate,
  dayUrl,
  customCss,
}) => {
  const isMobile = useIsMobile();
  const [openShare, setOpenShare] = useState(false);
  const [copied, setCopied] = useState(false);

  // Format Day data for social media
  const { sharingHashtags, day } = useContext(DayContext);

  const shareUrl = dayUrl || 'An error occurred.';
  const shareDate = convertDate(dayDate || 'YYYY-MM-DD');

  // Ignore custom image icons
  const shareEmoji = day.customReaction?.textEmoji ?? '';
  const dayShareText = `${shareDate} is ${dayName}!${shareEmoji}\n\n${shareUrl}\n\n${sharingHashtags}`;

  // Generate the relevant social media URLs with formatted query strings
  const socialLinks = shareLinks({
    shareUrl: shareUrl,
    shareText: dayShareText,
  });

  // Close menu after copying url
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenShare(false);
      setCopied(false);
    }, 1100);

    if (copied) {
      timer;
    }
    return () => {
      clearTimeout(timer);
    };
  }, [copied]);

  const handleToggle = () => {
    setOpenShare(!openShare);
  };

  const handleOpen = () => {
    setOpenShare(true);
  };

  const handleClose = () => {
    setOpenShare(false);
  };

  // Copy URL to clipboard and close menu after delay
  const handleCopy = async () => {
    customTagWithDate('copy', day.data.theDate!, day.data.initiative!);
    if (navigator) {
      try {
        await navigator.clipboard.writeText(shareUrl);
        setCopied(true);
      } catch (e) {
        console.error('Failed to copy to the clipboard.', e);
        alert('There was an error copying to the clipboard.');
      }
    }
  };

  // Variants receive 'custom' prop as 'i' for staggered delay
  const itemVariants = useMemo<Variants>(
    () => ({
      hidden: (i) => ({
        x: 0,
        transition: {
          duration: 0.5,
          delay: 0.05 * i,
        },
      }),
      visible: (i) => ({
        x: `${i % 2 === 0 ? '-' : ''}${Math.round(i / 2) * 4.25}rem`,
        transition: {
          duration: 0.5,
          delay: 0.05 * Math.round(i / 2),
        },
      }),
    }),
    [],
  );

  const effectTransform = tw`[transform:scale(0.9)] origin-[30%]`;
  Modal.setAppElement('#gatsby-focus-wrapper');
  return (
    <ShareWrapper
      // Disable hover for mobile
      onMouseOver={() => !isMobile && handleOpen}
      onMouseOut={() => !isMobile && handleClose}
      onClick={() => handleOpen}
      animate={openShare ? 'visible' : 'hidden'}
    >
      {
        /* Elaborate way to be able to use the 'goo' effect on expand with the
           special hand-drawn button outlines, without the blur showing at rest:
           animate a duplicate set of buttons that's slightly scaled down 😶 */
        ['effect', 'ui'].map((layer) => (
          <ButtonContainer
            key={layer}
            css={[
              layer === 'effect'
                ? [tw`absolute top-0 [filter:url(#goo-${effectId})]`]
                : tw`relative`,
              customCss,
            ]}
          >
            <JauntyButton
              customCss={[
                shareStyles,
                tw`z-10`,
                layer === 'effect' && effectTransform,
              ]}
              onClick={layer === 'ui' ? handleToggle : undefined}
              title="Share this day"
              // prevent screenreaders from reading out buttons twice
              ariaHidden={layer === 'effect' ? true : false}
              tabIndex={layer === 'effect' ? -1 : 0}
            >
              {layer === 'ui' && <ShareIcon tw="w-4 h-4" />}
            </JauntyButton>

            {!isMobile &&
              socialLinks.map((link, idx) => {
                return (
                  <PositionDiv
                    custom={1 + idx}
                    key={link.name}
                    variants={itemVariants}
                  >
                    <div css={layer === 'effect' && effectTransform}>
                      {link.name === 'CopyToClipboard' ? (
                        <JauntyButton
                          key={link.name}
                          customCss={shareStyles}
                          title="Copy to clipboard"
                          onClick={handleCopy}
                          // prevent screenreaders from reading out buttons twice
                          ariaHidden={layer === 'effect' ? true : false}
                          tabIndex={layer === 'effect' ? -1 : 0}
                        >
                          {layer === 'ui' && link.icon}
                          {layer === 'ui' && copied && openShare && (
                            <div tw="absolute -bottom-10 text-xs text-theme-fg -left-2.5">
                              Copied!
                            </div>
                          )}
                        </JauntyButton>
                      ) : (
                        <JauntyButton
                          customCss={[shareStyles]}
                          onClick={() => {
                            customTagWithDate(
                              link.name.toLowerCase(),
                              day.data.theDate!,
                              day.data.initiative!,
                            );
                            return (
                              !isSSG &&
                              window.open(
                                link.link,
                                '_blank',
                                'noopener,noreferrer',
                              )
                            );
                          }}
                          title={`Share on ${link.name}`}
                          // prevent screenreaders from reading out buttons twice
                          ariaHidden={layer === 'effect' ? true : false}
                          tabIndex={layer === 'effect' ? -1 : 0}
                        >
                          {layer === 'ui' && link.icon}
                        </JauntyButton>
                      )}
                    </div>
                  </PositionDiv>
                );
              })}
          </ButtonContainer>
        ))
      }
      {isMobile && openShare && (
        <ShareModal
          isOpen={openShare}
          isCopied={copied}
          close={handleClose}
          handleCopy={handleCopy}
          socialLinks={socialLinks}
        />
      )}

      <GooEffectSvg svgId={effectId} />
    </ShareWrapper>
  );
};

const ShareWrapper = tw(motion.div)`relative mt-4 sm:mt-8`;
const PositionDiv = tw(motion.div)`absolute px-4 hidden sm:block`;
const ButtonContainer = styled(motion.div)(() => [
  tw`flex items-center gap-2 justify-center inset-x-0 absolute md:(bottom-0)`,
]);
const shareStyles = [tw`w-[52px] h-[52px]`];
